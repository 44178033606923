$(document).ready(function(){
    var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');

    Dropzone.autoDiscover = false;
    var myDropzone = $(".dropzone").dropzone({
        url: "/admin/file_manager",
        sending: function(file, xhr, formData) {
            formData.append("_token", CSRF_TOKEN);
            var current_path = $('.dropzone').attr('data-path');
            if(current_path){
                formData.append("path", current_path);
            } else {
                formData.append("path", '');
            }

        }
    });

    $('.upload-btn').on('click', function(){
        $('.upload-btn').addClass('hide');
        $('.files-btn').removeClass('hide');
        $('.files').hide();
        $('.upload-form').show();
    });

    $('.files-btn').on('click', function(){
        location.reload();
    });

    $('.file .user-image').on('click', function(){
        $file_check = $(this).find('.file-check');
        $file_check.toggle();
        $file_check_input = $file_check.find('input');
        var val = $file_check_input.val();
        $file_check_input.val(val === "true" ? "false" : "true");
    });

    $('#user-url').click(function() {
        $(this).select();
    });

    $('.delete-files').on('click', function(){
        var delete_files = [];
        $('.file-input').each(function(){
            if($(this).val() == 'true'){
                delete_files.push($(this).attr('name'));
                $(this).closest('.file-box')[0].remove();
            }
        });
        $.ajax({
            url: "/admin/file_manager",
            method: "delete",
            data: {
                _token: CSRF_TOKEN,
                files: delete_files
            }
        });
    });

});