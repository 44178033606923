tinymce.PluginManager.add('custom_page_linker', function(editor, url) {
    editor.addButton('custom_page_linker', {
        text: 'Custom page link',
        icon: false,
        onclick: function() {
            var selected_text = editor.selection.getContent({format: 'text'});
            dialog({text: selected_text});
        }
    });

    editor.on('ObjectSelected', function(e) {
        var $object = $(e.target);
        var content = $(e.target).attr('data-mce-content');
        var regex = /{{ text='([^']+)' id='([0-9]+)' target='([^']+)' }}/g;
        var matches = regex.exec(content);


        if(regex.test(content)){
            dialog({
                id: matches[2],
                text: matches[1],
                taget: matches[3]
            }, $object);
        }
    });

    // Adds a menu item to the tools menu
    editor.addMenuItem('custom_page_linker', {
        text: 'Custom page link',
        context: 'tools',
        onclick: function() {
            dialog();
        }
    });

    function dialog(attr, $object){
        editor.windowManager.open({
            title: 'Custom page linker',
            body: [
                {
                    type: 'listbox',
                    name: 'id',
                    label: 'Custom page',
                    values: custom_page_options,
                    value: (attr && attr.id ? attr.id : '')
                },
                {
                    type: 'textbox',
                    name: 'text',
                    label: 'Link text',
                    value: (attr && attr.text ? attr.text : '')
                },
                {
                    type: 'listbox',
                    name: 'target',
                    label: 'Target',
                    values: [
                        {text: '_self', value: '_self'},
                        {text: '_blank', value: 'blank'},
                        {text: '_parent', value: '_parent'},
                        {text: '_top', value: '_top'}
                    ],
                    value: (attr && attr.target ? attr.target : '')
                }
            ],
            onsubmit: function(e) {
                var data = {
                    'text': e.data.text,
                    'id': e.data.id,
                    'target': e.data.target
                };
                if($object){
                    $object.attr('data-mce-content', generateContent(data));
                    $object.text(data.text);
                } else {
                    editor.insertContent(generateContent(data));
                }
            }
        });
    }

    function generateContent(attr){
        return '{{ text=\'' + attr.text + '\' id=\''+ attr.id +'\' target=\'' + attr.target + '\' }}';
    }
});
