$(document).ready(function(){
    var $tiny = tinymce.init({
        selector: '.tinymce',
        plugins: [
            'advlist autolink lists link image charmap print preview hr anchor pagebreak',
            'searchreplace wordcount visualblocks visualchars code fullscreen',
            'insertdatetime media nonbreaking save table contextmenu directionality',
            'template paste textcolor colorpicker textpattern imagetools hr noneditable noneditable gallery'
        ],
        toolbar1: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
        toolbar2: 'forecolor backcolor | table | hr | code | gallery',
        image_advtab: true,
        paste_data_images: true,
        cleanup_on_startup : false,
        cleanup : false,
        verify_html : false,
        forced_root_block : '',
        force_p_newlines : true,
        height : 250,
        paste_auto_cleanup_on_paste : false,
        valid_children : "+a[abbr|address|map|article|aside|audio|b|bdo|blockquote|br|canvas|cite|code|data|datalist|del|dfn|div|dl|em|fieldset|figure|footer|form|h1|h2|h3|h4|h5|h6|header|hr|i|img|ins|kbd|main|map|mark|math|meter|nav|noscript|object|ol|output|p|pre|progress|q|ruby|s|samp|script|section|small|span|strong|sub|sup|svg|table|template|time|u|ul|var|video|wbr]",
        file_browser_callback   : function(field_name, url, type, win) {
            tinymce.activeEditor.windowManager.open({
                file            : "/admin/file_manager?tinymce=true&type="+type,
                title           : 'Select an Image',
                width           : 1200,
                height          : 600,
                resizable       : "yes",
                inline          : "yes",
                close_previous  : "yes"
            }, {
                window  : win,
                input   : field_name,
                type    : type
            });
        },
        link_list: '/admin/custom_page/options'

    });
});