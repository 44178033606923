tinymce.PluginManager.add('gallery', function(editor, url) {
    editor.addButton('gallery', {
        text: 'Gallery',
        icon: false,
        onclick: function() {
            tinymce.activeEditor.windowManager.open({
                file            : "/admin/tinymce/gallery",
                title           : 'Gallery',
                width           : 800,
                height          : 480,
                resizable       : "yes",
                inline          : "yes",
                close_previous  : "yes",
                buttons: [{
                    text: 'Close',
                    onclick: 'close'
                }]
            });
        }
    });
});
